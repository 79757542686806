<template>
  <img
    src="@/assets/AlustaConfiguratorLogo.png"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 0"
  />
  <img
    src="@/assets/glasvandoren.jpg"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 1"
  />
  <img
    src="@/assets/jensz.png"
    class="img-fluid mt-2"
    v-if="$store.getters.getCustomizationNumber == 2"
  />
  <img
    src="@/assets/DutchGlassDesign.png"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 3"
  />
  <img
    src="@/assets/Logo_Verdouw.png"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 4"
  />
  <img
    src="@/assets/Logo_Camver.webp"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 5"
  />
  <img
    src="@/assets/Logo_Glassdesign.png"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 6"
  />
  <img
    src="@/assets/Logo_glasconceptvr.jpg"
    class="img-fluid"
    v-if="$store.getters.getCustomizationNumber == 7"
  />
</template>
<script>
export default {
  name: "LogoScreen",
  data() {
    return {};
  },
};
</script>
