<template>
  <div class="p-2">
    <SubTitle msg="" />
    <div class="text-center mb-3">
      <a
        :href="$t('HelpDeurkader.RekenvoorbeeldLink')"
        target="_blank"
        class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
        >{{ $t("HelpDeurkader.Rekenvoorbeeld") }}</a
      >
    </div>
    <!-- NEX -->
    <SubTitle msg="HelpDeurkader.NEXTitle" />
    <div>
      <img src="@/assets/Help/SpelingenNEX.png" class="img-fluid" />
      <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.zijstijlen") }} = 1,5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.bovenstijl") }} = 3 mm
          </li>
          <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 15 mm</li>
        </ul>
      </div>
      <div class="col-6">
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.deur") }} |
            {{ $t("HelpDeurmaten.zijstijlen") }} = 2,5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.deur") }} |
            {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} = 8
            mm
          </li>
          <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 15 mm</li>
        </ul>
      </div>
    </div>
    <p>{{ $t("HelpDeurkader.NexInfo01") }}</p>
    <!-- END NEX -->
    <!-- GP -->
    <SubTitle msg="HelpDeurkader.GPTitle" />
    <div>
      <img src="@/assets/Help/SpelingenGP.png" class="img-fluid" />
      <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.zijstijlen") }} = 1,5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.bovenstijl") }} = 50 mm
          </li>
          <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 40 mm</li>
        </ul>
      </div>
      <div class="col-6">
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.deur") }} |
            {{ $t("HelpDeurmaten.zijstijlen") }} = 2,5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.deur") }} |
            {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} = 8
            mm
          </li>
          <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 40 mm</li>
        </ul>
      </div>
    </div>
    <p>{{ $t("HelpDeurkader.GPInfo01") }}</p>
    <!-- END GP -->
    <!-- GEEN -->
    <SubTitle msg="HelpDeurkader.GeenTitle" />
    <div>
      <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <img src="@/assets/Help/SpelingenGeen.png" class="img-fluid" />
      </div>
      <div class="col-6">
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.zijstijlen") }} = 1,5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Opening") }} |
            {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} = 8
            mm
          </li>
        </ul>
      </div>
    </div>
    <p>{{ $t("HelpDeurkader.GeenInfo01") }}</p>
    <!-- END GEEN -->
  </div>
</template>
<script>
export default {
  name: "HelpDeurkader",
};
</script>
