export default {
  "Base": {
    "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
    "Rechts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
    "Verticaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
    "Horizontaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download visualisation"])},
    "greeplengte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handle length"])}
  },
  "MainTitle": {
    "Deuren/panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors/panels"])},
    "Kaderverdeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame mullions"])},
    "Deurgrepen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door handles"])},
    "Glas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass"])},
    "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour and finishing"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price calculation"])},
    "Deurkaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door frame"])},
    "ScharnierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinge type"])}
  },
  "SubTitle": {
    "Producttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product type"])},
    "Deurtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door type"])},
    "Draairichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction of rotation"])},
    "Openingsrichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening direction"])},
    "Deurmaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door/panel dimensions"])},
    "Panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number and dimensions of extra panels"])},
    "Draaipunt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door pivot point distance"])},
    "Aantal/positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number/position mullions"])},
    "Greeptype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handle type"])},
    "Maten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
    "Positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position of the handle"])},
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With lock in the middle"])},
    "Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock plate"])},
    "Glassoorten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass types"])},
    "Deurslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock in the bottom plinth"])},
    "SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock bushing"])},
    "Cilinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder"])},
    "ALOType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile type"])},
    "Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infill surface"])},
    "Ralkleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ral-colour"])},
    "Lakafwerking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paint-finish"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic"])},
    "Zijspeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side clearance"])},
    "AsTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOLS"])},
    "Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side sliding lock"])},
    "Deurkadermaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door frame dimensions"])},
    "Scharnieren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinge position"])},
    "Magneetsluiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnetic Closure Position"])}
  },
  "Producttype": {
    "Hydraulische": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic rotating door"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single fixed panel"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door and panel combinations can be configured as required as you proceed."])},
    "Sliding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliding door"])}
  },
  "Deurtype": {
    "Enkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single door"])},
    "Dubbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double door"])}
  },
  "Deurmaten": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door/panel height"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door/panel width"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The door spanner sizes for the above door sizes are: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The height is maximum 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The width is maximum 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight of the door may be max. 100kg. Please reduce the dimensions of the door."])}
  },
  "Deurkader": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door frame height"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door frame width"])},
    "Nex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX. DOOR FRAME"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP. DOOR FRAME"])},
    "Zonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WITHOUT DOOR FRAME"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The span dimensions for the above door frame sizes, considering the proposed tolerances, are:  ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The span dimensions for the above door frame sizes, considering the proposed tolerances, are:  ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of hinges is determined based on the door height."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The height is maximum 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The width is maximum 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight of the door may be max. 100kg. Please reduce the dimensions of the door."])}
  },
  "ScharnierType": {
    "Nex05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX05"])},
    "DF05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF05"])}
  },
  "Scharnieren": {
    "Standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "OpMaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjusted"])}
  },
  "AsTools": {
    "TextTool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since your door width is larger than 1010 mm, it is recommended to purchase ASTOOL1 and ASTOOL2 to set the end and closing speed and top spindle."])},
    "TextToolXL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since your door width is larger than 1200 mm, it is recommended to purchase ASTOOL1XL and ASTOOL2XL to set the end and closing speed and top spindle."])},
    "AsTool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1 - L: 300mm"])},
    "AsTool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2 - L: 300mm"])},
    "AsTool1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1XL - L: 600mm"])},
    "AsTool2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2XL - L: 600mm"])}
  },
  "Panelen": {
    "PaneelNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Panel ", _interpolate(_list(0)), " (mm)"])},
    "AfwijkendeSituatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deviating situations"])},
    "AfwijkendeSituatieLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/afwijkende_panelen.pdf"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The width is maximum 1500 mm!"])},
    "Asymmetrisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asymmetrical"])},
    "TProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-Profile"])},
    "IProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I-Profile"])},
    "ProfielInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you select an AMUR SQ or AMT handle and position it on a profile, it is recommended to choose an I-profile. A T-profile is also technically possible, but is a more expensive option and less user-friendly due to the raised edge."])}
  },
  "Draaipunt": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The preset distance is the most recommended choice for the above door sizes."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend moving the pivot point more. The chosen position is out of guarantee."])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The pivot point cannot be more than half the door width (", _interpolate(_list(0)), " mm)!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pivot point cannot be smaller than 110 mm!"])}
  },
  "Zijspeling": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clearance between wall and sliding door"])}
  },
  "Greeptype": {
    "Hoekgreep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corner handle"])},
    "AMURSQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ handle"])},
    "AMT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT handle"])},
    "NEXHG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX corner handle"])},
    "NEXEG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX ergonomic corner handle"])},
    "NXCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX door handle with lock"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This handle is too big for this door."])}
  },
  "Maten": {
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoekgreep with center lock"])}
  },
  "Glassoort": {
    "Klaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear (10 mm)"])},
    "Grijs10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grey (10 mm)"])},
    "Brons10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (10 mm)"])},
    "AcideKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acid-etched Clear (10 mm)"])},
    "AcideExtraKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acid-etched Extra Clear (10 mm)"])},
    "Kathedraal8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedraal (8 mm)"])},
    "Raywall8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raywall (8 mm)"])},
    "Klaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear (6 mm)"])},
    "ExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Clear (6 mm)"])},
    "Grijs06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grey (6 mm)"])},
    "Brons06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (6 mm)"])},
    "AcideKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acid-etched Clear (6 mm)"])},
    "AcideExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acid-etched Extra Clear (6 mm)"])},
    "DarkGrey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Grey (6 mm)"])}
  },
  "Slot": {
    "SlotX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock X (mm)"])},
    "0Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No lock"])},
    "1Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With lock"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lock is located in the lower profile. To open and lock the door, you will have to bend down."])},
    "0SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without lock bushing"])},
    "1SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With lock bushing"])},
    "0CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black anodized"])},
    "1CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polished chrome"])},
    "0CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyed alike"])},
    "1CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyed differently"])},
    "0Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull handle without center lock"])},
    "1Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull handle with center lock"])},
    "0Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without lock plate"])},
    "1Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With lock plate"])},
    "0Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No side sliding lock"])},
    "1Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side sliding lock matt black"])},
    "2Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side sliding lock aluminum matt"])}
  },
  "Opvulvlak": {
    "0Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Infill surface"])},
    "1Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the bottom"])},
    "2Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the middle"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The infill surface is located at the bottom of the door up to the first horizontal subdivision."])}
  },
  "Knoppen": {
    "Opslaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to EGDesign"])},
    "Terug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to EGDesign without saving"])},
    "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "MailVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
  },
  "ErrorAlert": {
    "ErLiepIetsMis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong!"])},
    "VulAllesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in everything correctly!"])},
    "InvalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong key"])}
  },
  "HelpDeurgrepen": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The horizontal positioning (h) of the door handle is determined from the centre line of the door handle to the extreme right edge of the door frame."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vertical position (v) of the door handle is determined from the centre of the door handle to the lower edge of the door frame."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want the position of the corner handle to be as shown in the visualization next to it, please fill in the following value for the vertical position: ½ x length of the corner handle + 68"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want the position of the corner handle to be as shown in the visualization next to it, please fill in the following value for the vertical position: ½ x length of the corner handle"])}
  },
  "HelpDeurmaten": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height door = height fixed panel."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in mind the following recommended clearances:"])},
    "Deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door"])},
    "vloer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["floor"])},
    "plafond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ceiling"])},
    "muur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wall"])},
    "deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["door"])},
    "paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["panel"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "Opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening"])},
    "zijstijlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side posts door frame"])},
    "bovenstijl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper post door frame"])},
    "Deurkaderbreedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door frame width"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tension dimensions are the smallest height and width dimensions measured in the opening where the door(s)/panel(s) should be."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a double door, the entered door width will be doubled."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_configNEX_en.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download calculation example"])}
  },
  "HelpDeurkader": {
    "NexInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to change the tolerances between the outside of the door frame and the opening, you must change the door frame dimensions. Note that the change in tolerances will be visible in the calculated span dimensions."])},
    "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX. door frame"])},
    "GPTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP. door frame"])},
    "GPInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to change the tolerances between the outside of the door frame and the opening, you must change the door frame dimensions. Note that the change in tolerances will be visible in the calculated span dimensions."])},
    "GeenTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without doorframe"])},
    "standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard tolerances:"])},
    "GeenInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not order the NEX or GP door frame, we recommend checking the above clearances."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_config_en.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download calculation example"])}
  },
  "HelpAsTools": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For door widths 1010 mm < x ≤ 1200 mm, it is recommended to order ASTOOL1 to set the end and closing speed if you do not have a flat head screwdriver with length 300 mm."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For door widths 1010 mm < x ≤ 1200 mm, it is recommended to order ASTOOL2 to set the top spindle if you do not have hexagon size 3 with length 300 mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For door widths 1200 mm < x ≤ 1500 mm, it is recommended to order ASTOOL1XL to set the end and closing speed if you do not have a flat head screwdriver with length 600 mm."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For door widths 1200 mm < x ≤ 1500 mm, it is recommended to order ASTOOL2XL to set the top spindle if you do not have hexagon size 3 with length 600 mm."])}
  },
  "HelpDraaipunt": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pivot point distance (a) is the distance from the centre of the bottom pivot to the outer edge of the door frame. If you save this configuration in EGDesign, the value a - 19 mm will appear on the drawing because here the pivot point distance (a) is the distance from the centre of the bottom pivot to the outer edge of the glass."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the sake of structural strength, we recommend not moving the predetermined pivot point distance."])}
  },
  "HelpOnderverdeling": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The position of the vertical glazing bar is determined by the distance between the centre line of the glazing bar and the extreme left or right edge of the door frame depending on the chosen direction of rotation. If you save this configuration in EGDesign, the value v - 19 mm will appear on the drawing because this determines the distance from the glazing bar's centreline to the outer edge of the glass."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The position of a horizontal glazing bar is determined by the distance between the centre line of a glazing bar and the lower edge of the door frame. If you save this configuration in EGDesign, the value h - 66 mm will appear on the drawing because this determines the distance from the centre line of the glazing batten to the lowper edge of the glass."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the horizontal and vertical submullions are placed symmetrically with respect to the glass. The different glass surfaces between de submullions are the same size. (A=A=A) (B=B=B) With an asymmetrical subdivision, the position is determined relative to the door frame. See below:"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want the door handle in the middle of a horizontal subdivision, you can easily adjust this in EGDesign."])}
  },
  "HelpTrekkermetslot": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center lock is positioned at the center of the door puller in combination with an AMURSQ/AMT pull handle. Contact your dealer if you wish to position the center lock vertically differently."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center lock is positioned in the center of the door puller in combination with an AMURSQ/AMT pull handle. In EGDesign, you can position the center lock vertically differently from the position of the pull handle."])}
    }
  },
  "HelpRalkleur": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL-colour 9005 in combination with textured paint is standard."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is your desired RAL-colour not listed in the configurator? Ask your dealer which RAL-colours are possible."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A digital representation of colours is never truthful. Therefore, ask your dealer to view samples of the desired colour and finish."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to give certain accessories a different colour and finish? This is possible! Ask your dealer."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL-colour 9005 in combination with textured paint is standard."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the desired RAL-colour not in the configurator? It is possible to select other RAL-colours via EGDesign."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A digital representation of colours is never truthful. Always offer your customer samples."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through EGDesign it is possible to assign accessories a different colour and finish."])}
    },
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in mind that choosing a different colour and finish may lead to a longer delivery time."])}
  },
  "HelpLakafwerking": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When choosing a RAL-colour, it is necessary to choose a desired paint finish."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To achieve the steellook-effect, textured paint is recommended. Ask your dealer for samples to compare the different finishes."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When choosing a RAL-colour, it is necessary to choose a desired paint finish."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To achieve the steellook-effect, textured paint is recommended. Offer your customer samples of the different paint finishes."])}
    }
  },
  "HelpScharnieren": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NEX door frame is standardly equipped with NEX05R/L hinges."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to change the position of the hinges in the configurator."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to change the color of the hinges to differ from other components via your dealer."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to order only the NEX door frame without a hinged door through your dealer."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NEX door frame does not allow combinations with sidelights."])}
    },
    "Dealer": {
      "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX05L/R hinges"])},
      "DF05Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF05L/R hinges"])},
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NEX door frame is standardly equipped with NEX05R/L hinges."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to change the position of the hinges in the configurator."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to change the color of the hinges in EGDesign."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to order only the NEX door frame by removing the door in EGDesign."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NEX door frame does not allow combinations with sidelights."])},
      "InfoNEX01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NEX05L/R hinges are specifically designed for use in combination with the NEX door frame, which you can also select in the configurator."])},
      "InfoNEX02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to apply these hinges elsewhere, we recommend checking the technical data sheet for the cutouts on the website."])},
      "InfoNEX03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When determining the position of the hinges and the door sizes, we recommend using your specific door frame as a reference point."])},
      "InfoDF0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The DF05L/R hinges are specifically designed for use in our existing door frame range (GP, SF, SW) and in wooden door frames."])},
      "InfoDF0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to apply these hinges elsewhere, we recommend checking the technical data sheet for the cutout on the website."])},
      "InfoDF0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When determining the position of the hinges and the door sizes, we recommend using your specific door frame as a reference point."])}
    }
  },
  "HelpAnodic": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The anodised collection gives an anodised effect and goes perfectly with the Alusta product range."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the anodised collection, it is not possible to choose a paint finish."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A digital representation of colours is never truthful. Therefore, ask your dealer to view samples of the desired colour and finish."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The anodised collection gives an anodised effect and goes perfectly with the Alusta product range."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the anodised collection, it is not possible to choose a paint finish."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A digital representation of colours is never truthful. Always offer your customer samples."])}
    }
  },
  "OfferteFormulier": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "MainTitleVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details"])},
    "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions about this Alusta-configuration?"])},
    "voornaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "straat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street + nr"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "gemeente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "welkeDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which dealer did you find us through?"])},
    "bedankt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you very much for your request. We will contact you soon!"])}
  },
  "SelecteerKleur": {
    "structuur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure"])},
    "mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matt"])},
    "glanzend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shiny"])}
  },
  "ColorList": {
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 structure Jet Black"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other colour or finish"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 Jet Black"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9016 Signage white"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9010 Pure white"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7016 Antracite grey"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7021 Black grey"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Natura"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Saphire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Malachite"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Champagne"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Gold"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Bronze"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic Brown"])}
  },
  "Error": {
    "glasstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Referenties": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order references"])},
    "KlantRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order reference"])},
    "Referentie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order line reference"])}
  },
  "Pricing": {
    "btw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price calculation excluding VAT and installation costs"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alusta kit (door/panel)"])},
    "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paint surcharge"])},
    "horizontalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal transoms"])},
    "verticalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical mullions"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infill surface"])},
    "AMT200300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT handle 300mm"])},
    "AMT200500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT handle 500mm"])},
    "AMT200700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT handle 700mm"])},
    "AMT201200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT handle 1200mm"])},
    "AMUR200300SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ handle 300mm"])},
    "AMUR200500SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ handle 500mm"])},
    "AMUR201200SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ handle 1200mm"])},
    "AMUR302000SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ handle 2000mm"])},
    "ASCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASCS01 cylinderlock with plate and 3 keys"])},
    "ASHG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG01 corner handle 150mm"])},
    "ASHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG1200 corner handle 1200mm"])},
    "ASHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG2000 corner handle 2000mm"])},
    "ASHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG300 corner handle 300mm"])},
    "ASHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG500 corner handle 500mm"])},
    "ASHGMAAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHGMAAT corner handle made to measure"])},
    "ASTOOL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1 Alusta tool 1"])},
    "ASTOOL1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1XL Alusta tool 1XL"])},
    "ASTOOL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2 Alusta tool 2"])},
    "ASTOOL2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2XL Alusta tool 2XL"])},
    "V515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V515 lock bushing"])},
    "V017S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017S keyed alike cylinder including 3 keys"])},
    "V017D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017D non-keyed alike cylinder including 3 keys"])},
    "AS522HG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522HG corner handle 100mm with center lock"])},
    "AS522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522 corner handle"])},
    "V532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V532 lock plate"])},
    "061_7168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["061.7168 side sliding lock"])}
  }
}